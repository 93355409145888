@import url('https://fonts.googleapis.com/css?family=Vollkorn');
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: url('_assets/font/NotoSansJP-Regular.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 700;
  src: url('_assets/font/NotoSansJP-Bold.woff') format('woff');
  font-display: swap;
}
body{
  font-family: 'Noto Sans Japanese', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}//body
img{
  vertical-align: middle;
  max-width: 100%;
}
a{
  color: inherit;
  text-decoration: none;
}
